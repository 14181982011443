var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","transition":"slide-y-transition"},model:{value:(_vm.addEditDialog),callback:function ($$v) {_vm.addEditDialog=$$v},expression:"addEditDialog"}},[_c('v-card',{staticClass:"dialog-card-border"},[_c('v-card-title',{staticClass:"text-h6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('Update Currency') : _vm.$t('Add New Currency'))+" ")]),_c('validation-observer',{ref:"theForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Code'))),"outlined":"","dense":"","color":_vm.$_input_color(),"error-messages":errors},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Symbol'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Symbol'))),"outlined":"","dense":"","color":_vm.$_input_color(),"error-messages":errors},model:{value:(_vm.formData.symbol),callback:function ($$v) {_vm.$set(_vm.formData, "symbol", $$v)},expression:"formData.symbol"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Ex.rate'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":("* " + (_vm.$t('Ex.rate'))),"outlined":"","dense":"","color":_vm.$_input_color(),"error-messages":errors},model:{value:(_vm.formData.ex_rate),callback:function ($$v) {_vm.$set(_vm.formData, "ex_rate", $$v)},expression:"formData.ex_rate"}})]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('app-form-tip'),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","outlined":"","small":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","depressed":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.editMode ? 'mdil-check' : 'mdil-plus')+" ")]),_c('span',{staticClass:"pe-2"},[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t('Save') : _vm.$t('Add'))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }